import { Modal, Drawer, ModalBody, DrawerBody, ModalHeader, ModalOverlay, ModalContent, DrawerContent, DrawerCloseButton, } from '@chakra-ui/react';
import { faXTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes, faStream } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPublishedAt } from '@main/app/features/article/article.helper';
import Image from '@main/ui/atoms/Image';
import ArticleListItem from '@main/ui/components/ArticleListItem';
import CloudflareStreamPlayer from '@main/ui/components/CloudflareStreamPlayer';
import IconButton from '@main/ui/components/IconButton';
import VimeoPlayer from '@main/ui/components/VimeoPlayer';
import MainLayout from '@main/ui/layouts/MainLayout';
import ArticleMemoPopupForm from './components/ArticleMemoPopupForm';
import CompleteButton from './components/CompleteButton';
import LessonCourseOverview from './components/LessonCourseOverview';
import MemoButton from './components/MemoButton';
import PlaylistSaveModal from './components/PlaylistSaveModal';
import PlaylistViewContainer from './components/PlaylistViewContainer';
import ReachedTrialLimitPlayer from './components/ReachedTrialLimitPlayer';
import useHook from './hook';
export var Component = function (props) {
    var _a;
    var _b = useHook(props), memo = _b.memo, article = _b.article, hasMemo = _b.hasMemo, setMemo = _b.setMemo, playlist = _b.playlist, isMobile = _b.isMobile, canShare = _b.canShare, closeModal = _b.closeModal, isCompleted = _b.isCompleted, isModalOpen = _b.isModalOpen, isMemoSynced = _b.isMemoSynced, relatedItems = _b.relatedItems, lessonCourse = _b.lessonCourse, isMemoShowing = _b.isMemoShowing, sortableItems = _b.sortableItems, onMemoClicked = _b.onMemoClicked, plDrawerTopRef = _b.plDrawerTopRef, shareToTwitter = _b.shareToTwitter, shareToFacebook = _b.shareToFacebook, setIsMemoShowing = _b.setIsMemoShowing, setSortableItems = _b.setSortableItems, onCompleteClicked = _b.onCompleteClicked, showPlaylistsModal = _b.showPlaylistsModal, isCompleteUpdating = _b.isCompleteUpdating, showPlaylistDrawer = _b.showPlaylistDrawer, isReachedTrialLimit = _b.isReachedTrialLimit, closePlaylistDrawer = _b.closePlaylistDrawer, closePlaylistsModal = _b.closePlaylistsModal, usePlaylistSaveProps = _b.usePlaylistSaveProps, isPlaylistDrawerOpen = _b.isPlaylistDrawerOpen, isPlaylistsModalOpen = _b.isPlaylistsModalOpen, includedLessonCourses = _b.includedLessonCourses, onShowLessonCourseClicked = _b.onShowLessonCourseClicked;
    return (<>
      <MainLayout>
        <div className="lg:flex">
          <div className="mb-8 flex-1 lg:mb-0">
            <div className="mb-3">
              {isReachedTrialLimit ? (<ReachedTrialLimitPlayer />) : (<>
                  {article.cloudflareStreamId ? (<div className="relative aspect-[16/9] bg-gray-200">
                      <CloudflareStreamPlayer src={article.cloudflareStreamId}/>
                    </div>) : (<VimeoPlayer videoId={article.vimeoId}/>)}
                </>)}
            </div>
            <div ref={plDrawerTopRef} className="mb-2 text-lg font-bold">
              {article.title}
            </div>
            <div className="mb-3 md:flex md:justify-between">
              <div className="flex items-center">
                <div className="text-xs text-gray-600 sm:text-base">
                  {formatPublishedAt(article)} 公開
                </div>
              </div>

              <div className="hidden-scrollbar -mx-5 mt-1 flex items-center gap-x-1 overflow-y-scroll px-5 py-1 sm:mx-0 sm:px-0">
                {canShare && (<>
                    <IconButton icon={faXTwitter} onClicked={shareToTwitter} classNames="text-gray-700 hover:bg-gray-200 active:bg-gray-200"/>

                    <IconButton icon={faFacebookF} onClicked={shareToFacebook} classNames="text-gray-700 hover:bg-gray-200 active:bg-gray-200"/>
                  </>)}

                <IconButton icon={faStream} caption="保存する" onClicked={showPlaylistsModal} classNames="text-gray-700 hover:bg-gray-200 active:bg-gray-200"/>

                <MemoButton hasMemo={hasMemo} onClicked={onMemoClicked}/>

                <CompleteButton isCompleted={isCompleted} onClicked={onCompleteClicked} isLoading={isCompleteUpdating}/>
              </div>
            </div>

            <div className="mb-5 border-y border-solid border-gray-200 py-4">
              <div className="flex justify-between">
                <div className="mr-4">
                  <div className="h-[60px] w-[60px] overflow-hidden rounded-full">
                    <img alt="" loading="lazy" src={article.coachImageUrl} className="block h-full w-full object-cover"/>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="mb-1 text-sm font-bold">
                    {article.coachName}
                  </div>
                  <div className="text-sm">{article.coachDescription}</div>
                </div>
              </div>
            </div>
            {includedLessonCourses.length > 0 && (<div className="mt-5">
                <div className="mb-2 text-base font-bold">
                  この動画が含まれるレッスンコース
                </div>

                <div className="-m-1 flex flex-wrap">
                  {includedLessonCourses.map(function (course) { return (<div key={course.id} className="w-1/2 md:w-3/12">
                      <a href={"/features/".concat(course.id)} className="m-1 block">
                        <Image url={course.thumbnailUrl}/>
                      </a>
                    </div>); })}
                </div>
              </div>)}
          </div>

          {lessonCourse && (<>
              <div className="ml-8 hidden w-[380px] lg:block">
                <LessonCourseOverview article={article} title={lessonCourse.title} thumbnailUrl={lessonCourse.thumbnailUrl} description={lessonCourse.description} items={lessonCourse.items} lessonCourseId={lessonCourse.id} isChallenging={lessonCourse.isChallenging} articleIdToCompletedAt={lessonCourse.articleIdToCompletedAt}/>
              </div>
              <div onClick={onShowLessonCourseClicked} className="fixed bottom-0 left-0 z-10 flex w-full cursor-pointer items-center border-t border-solid border-gray-200 bg-white p-2 pb-5 lg:hidden">
                <div className="mr-3 aspect-[16/9] w-[120px] overflow-hidden rounded">
                  <img alt="" loading="lazy" src={lessonCourse.thumbnailUrl} className="block h-full w-full object-cover"/>
                </div>
                <div className="flex-1">
                  <div className="mb-1 text-xs leading-none text-gray-400">
                    レッスンコース
                  </div>
                  <div className="text-sm font-bold">{lessonCourse.title}</div>
                </div>
                <div className="flex flex-col items-center self-center px-2">
                  <FontAwesomeIcon icon={faBars} className="h-[32px] w-[32px] text-gray-700" fixedWidth/>
                  <div className="text-xs font-bold leading-none text-gray-700">
                    動画一覧
                  </div>
                </div>
              </div>
            </>)}
          {playlist && (<>
              <div className="ml-8 hidden w-[380px] lg:block">
                <PlaylistViewContainer playlist={playlist} article={article} sortableItems={sortableItems} setSortableItems={setSortableItems}/>
              </div>
              <div onClick={showPlaylistDrawer} className="fixed bottom-0 left-0 z-10 flex w-full cursor-pointer items-center border-t border-solid border-gray-200 bg-white p-2 pb-5 lg:hidden">
                <div className="mr-3 aspect-[16/9] w-[120px] overflow-hidden rounded">
                  <img alt="" loading="lazy" src={playlist.articles[0].thumbnailUrl} className="block h-full w-full object-cover"/>
                </div>
                <div className="flex-1">
                  <div className="mb-1 text-xs leading-none text-gray-400">
                    プレイリスト
                  </div>
                  <div className="text-sm font-bold">{playlist.title}</div>
                </div>
                <div className="flex flex-col items-center self-center px-2">
                  <FontAwesomeIcon icon={faBars} className="h-[32px] w-[32px] text-gray-700" fixedWidth/>
                  <div className="text-xs font-bold leading-none text-gray-700">
                    動画一覧
                  </div>
                </div>
              </div>
            </>)}
          {!(lessonCourse || playlist) && (<>
              <div className="mb-2 text-base font-bold lg:hidden">関連動画</div>
              <div className="lg:ml-8 lg:w-[380px]">
                {relatedItems.map(function (relatedItem) { return (<ArticleListItem key={relatedItem.article.id} id={relatedItem.article.id} length={relatedItem.article.length} title={relatedItem.article.title} coachName={relatedItem.article.coachName} thumbnailUrl={relatedItem.article.thumbnailUrl} isCompleted={relatedItem.isCompleted}/>); })}
              </div>
            </>)}
        </div>
      </MainLayout>
      {isMemoShowing && (<ArticleMemoPopupForm isSynced={isMemoSynced} content={memo} setContent={setMemo} onCloseButtonClicked={function () { return setIsMemoShowing(false); }}/>)}
      {lessonCourse && (<Modal isOpen={isModalOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent width="calc(100% - 20px)" maxWidth="800px">
            <ModalHeader>
              <div className="flex items-center justify-between">
                <div className="flex-1 text-lg">レッスンコース</div>
                <div className="">
                  <button onClick={closeModal} className="flex h-[48px] w-[48px] items-center justify-center rounded-full active:bg-gray-200">
                    <FontAwesomeIcon icon={faTimes} fixedWidth={true} className="text-gray-600"/>
                  </button>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="pb-4">
                <LessonCourseOverview article={article} title={lessonCourse.title} thumbnailUrl={lessonCourse.thumbnailUrl} description={lessonCourse.description} lessonCourseId={lessonCourse.id} items={lessonCourse.items} isChallenging={lessonCourse.isChallenging} articleIdToCompletedAt={lessonCourse.articleIdToCompletedAt}/>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>)}
      {playlist && isMobile && (<Drawer isOpen={isPlaylistDrawerOpen} onClose={closePlaylistDrawer} placement="bottom">
          <DrawerContent top={"".concat((_a = plDrawerTopRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().top)} className="w-full rounded border border-solid border-gray-100 pt-2">
            <DrawerBody>
              <DrawerCloseButton />
              <PlaylistViewContainer playlist={playlist} article={article} sortableItems={sortableItems} setSortableItems={setSortableItems}/>
            </DrawerBody>
          </DrawerContent>
        </Drawer>)}
      <PlaylistSaveModal isModalOpen={isPlaylistsModalOpen} closeModal={closePlaylistsModal} {...usePlaylistSaveProps}/>
    </>);
};
export default Component;
