var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var HeaderResendSignUpConfirmationEmailDocument = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation HeaderResendSignUpConfirmationEmail {\n  resendSignUpConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation HeaderResendSignUpConfirmationEmail {\n  resendSignUpConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useHeaderResendSignUpConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useHeaderResendSignUpConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeaderResendSignUpConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headerResendSignUpConfirmationEmailMutation, { data, loading, error }] = useHeaderResendSignUpConfirmationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useHeaderResendSignUpConfirmationEmailMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(HeaderResendSignUpConfirmationEmailDocument, options);
}
export var SendSuggestionDocument = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation sendSuggestion($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation sendSuggestion($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useSendSuggestionMutation__
 *
 * To run a mutation, you first call `useSendSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSuggestionMutation, { data, loading, error }] = useSendSuggestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSuggestionMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SendSuggestionDocument, options);
}
export var AuthNotPremiumBillingsErrorCreateCustomerPortalSessionDocument = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation AuthNotPremiumBillingsErrorCreateCustomerPortalSession($input: CreateCustomerPortalSessionInput!) {\n  session: createCustomerPortalSession(input: $input) {\n    url\n  }\n}\n    "], ["\n    mutation AuthNotPremiumBillingsErrorCreateCustomerPortalSession($input: CreateCustomerPortalSessionInput!) {\n  session: createCustomerPortalSession(input: $input) {\n    url\n  }\n}\n    "])));
/**
 * __useAuthNotPremiumBillingsErrorCreateCustomerPortalSessionMutation__
 *
 * To run a mutation, you first call `useAuthNotPremiumBillingsErrorCreateCustomerPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthNotPremiumBillingsErrorCreateCustomerPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authNotPremiumBillingsErrorCreateCustomerPortalSessionMutation, { data, loading, error }] = useAuthNotPremiumBillingsErrorCreateCustomerPortalSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthNotPremiumBillingsErrorCreateCustomerPortalSessionMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthNotPremiumBillingsErrorCreateCustomerPortalSessionDocument, options);
}
export var AuthPremiumBillingNewCreateCheckoutSessionDocument = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation AuthPremiumBillingNewCreateCheckoutSession {\n  session: createCheckoutSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumBillingNewCreateCheckoutSession {\n  session: createCheckoutSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumBillingNewCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useAuthPremiumBillingNewCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumBillingNewCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumBillingNewCreateCheckoutSessionMutation, { data, loading, error }] = useAuthPremiumBillingNewCreateCheckoutSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthPremiumBillingNewCreateCheckoutSessionMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumBillingNewCreateCheckoutSessionDocument, options);
}
export var AuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeDocument = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation AuthPremiumArticlesShowReachedTrialLimitPlayerUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumArticlesShowReachedTrialLimitPlayerUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeMutation__
 *
 * To run a mutation, you first call `useAuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumArticlesShowReachedTrialLimitPlayerUpgradeMutation, { data, loading, error }] = useAuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeDocument, options);
}
export var CreatePlaylistDocument = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation createPlaylist($input: CreatePlaylistInput!) {\n  createPlaylist(input: $input) {\n    clientMutationId\n    playlist {\n      id\n      title\n    }\n  }\n}\n    "], ["\n    mutation createPlaylist($input: CreatePlaylistInput!) {\n  createPlaylist(input: $input) {\n    clientMutationId\n    playlist {\n      id\n      title\n    }\n  }\n}\n    "])));
/**
 * __useCreatePlaylistMutation__
 *
 * To run a mutation, you first call `useCreatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaylistMutation, { data, loading, error }] = useCreatePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePlaylistDocument, options);
}
export var AddArticleToPlaylistDocument = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation addArticleToPlaylist($input: AddArticleToPlaylistInput!) {\n  addArticleToPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation addArticleToPlaylist($input: AddArticleToPlaylistInput!) {\n  addArticleToPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAddArticleToPlaylistMutation__
 *
 * To run a mutation, you first call `useAddArticleToPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArticleToPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArticleToPlaylistMutation, { data, loading, error }] = useAddArticleToPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddArticleToPlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddArticleToPlaylistDocument, options);
}
export var RemoveArticleFromPlaylistDocument = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation removeArticleFromPlaylist($input: RemoveArticleFromPlaylistInput!) {\n  removeArticleFromPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation removeArticleFromPlaylist($input: RemoveArticleFromPlaylistInput!) {\n  removeArticleFromPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useRemoveArticleFromPlaylistMutation__
 *
 * To run a mutation, you first call `useRemoveArticleFromPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArticleFromPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArticleFromPlaylistMutation, { data, loading, error }] = useRemoveArticleFromPlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveArticleFromPlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RemoveArticleFromPlaylistDocument, options);
}
export var UpdatePlaylistDocument = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation updatePlaylist($input: UpdatePlaylistInput!) {\n  updatePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation updatePlaylist($input: UpdatePlaylistInput!) {\n  updatePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useUpdatePlaylistMutation__
 *
 * To run a mutation, you first call `useUpdatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaylistMutation, { data, loading, error }] = useUpdatePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdatePlaylistDocument, options);
}
export var DeletePlaylistDocument = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    mutation deletePlaylist($input: DeletePlaylistInput!) {\n  deletePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation deletePlaylist($input: DeletePlaylistInput!) {\n  deletePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useDeletePlaylistMutation__
 *
 * To run a mutation, you first call `useDeletePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaylistMutation, { data, loading, error }] = useDeletePlaylistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeletePlaylistDocument, options);
}
export var AuthPremiumBillingCancelCancelDocument = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    mutation AuthPremiumBillingCancelCancel($input: CancelStripeInput!) {\n  cancelStripe(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumBillingCancelCancel($input: CancelStripeInput!) {\n  cancelStripe(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumBillingCancelCancelMutation__
 *
 * To run a mutation, you first call `useAuthPremiumBillingCancelCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumBillingCancelCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumBillingCancelCancelMutation, { data, loading, error }] = useAuthPremiumBillingCancelCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthPremiumBillingCancelCancelMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumBillingCancelCancelDocument, options);
}
export var AuthPremiumBillingTrialUpgradeDocument = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    mutation AuthPremiumBillingTrialUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumBillingTrialUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumBillingTrialUpgradeMutation__
 *
 * To run a mutation, you first call `useAuthPremiumBillingTrialUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumBillingTrialUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumBillingTrialUpgradeMutation, { data, loading, error }] = useAuthPremiumBillingTrialUpgradeMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthPremiumBillingTrialUpgradeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumBillingTrialUpgradeDocument, options);
}
export var AuthPremiumHomeHomePostModalMarkPostModalCheckedDocument = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    mutation AuthPremiumHomeHomePostModalMarkPostModalChecked($input: MarkPostModalCheckedInput!) {\n  response: markPostModalChecked(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumHomeHomePostModalMarkPostModalChecked($input: MarkPostModalCheckedInput!) {\n  response: markPostModalChecked(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumHomeHomePostModalMarkPostModalCheckedMutation__
 *
 * To run a mutation, you first call `useAuthPremiumHomeHomePostModalMarkPostModalCheckedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumHomeHomePostModalMarkPostModalCheckedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumHomeHomePostModalMarkPostModalCheckedMutation, { data, loading, error }] = useAuthPremiumHomeHomePostModalMarkPostModalCheckedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthPremiumHomeHomePostModalMarkPostModalCheckedMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumHomeHomePostModalMarkPostModalCheckedDocument, options);
}
export var AuthPremiumPracticesEditUpdatePracticeDocument = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    mutation authPremiumPracticesEditUpdatePractice($input: UpdatePracticeInput!) {\n  result: updatePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation authPremiumPracticesEditUpdatePractice($input: UpdatePracticeInput!) {\n  result: updatePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumPracticesEditUpdatePracticeMutation__
 *
 * To run a mutation, you first call `useAuthPremiumPracticesEditUpdatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumPracticesEditUpdatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumPracticesEditUpdatePracticeMutation, { data, loading, error }] = useAuthPremiumPracticesEditUpdatePracticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthPremiumPracticesEditUpdatePracticeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumPracticesEditUpdatePracticeDocument, options);
}
export var AuthPremiumPracticesDeletePracticeDocument = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    mutation authPremiumPracticesDeletePractice($input: DeletePracticeInput!) {\n  result: deletePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation authPremiumPracticesDeletePractice($input: DeletePracticeInput!) {\n  result: deletePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumPracticesDeletePracticeMutation__
 *
 * To run a mutation, you first call `useAuthPremiumPracticesDeletePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumPracticesDeletePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumPracticesDeletePracticeMutation, { data, loading, error }] = useAuthPremiumPracticesDeletePracticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthPremiumPracticesDeletePracticeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumPracticesDeletePracticeDocument, options);
}
export var AuthPremiumPracticesNewCreatePracticeDocument = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    mutation authPremiumPracticesNewCreatePractice($input: CreatePracticeInput!) {\n  result: createPractice(input: $input) {\n    practiceId\n    clientMutationId\n  }\n}\n    "], ["\n    mutation authPremiumPracticesNewCreatePractice($input: CreatePracticeInput!) {\n  result: createPractice(input: $input) {\n    practiceId\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumPracticesNewCreatePracticeMutation__
 *
 * To run a mutation, you first call `useAuthPremiumPracticesNewCreatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumPracticesNewCreatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumPracticesNewCreatePracticeMutation, { data, loading, error }] = useAuthPremiumPracticesNewCreatePracticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthPremiumPracticesNewCreatePracticeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumPracticesNewCreatePracticeDocument, options);
}
export var AuthPremiumUserAttributesEditUpdateAttributesDocument = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    mutation AuthPremiumUserAttributesEditUpdateAttributes($input: UpdateAttributesInput!) {\n  updateAttributes(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumUserAttributesEditUpdateAttributes($input: UpdateAttributesInput!) {\n  updateAttributes(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthPremiumUserAttributesEditUpdateAttributesMutation__
 *
 * To run a mutation, you first call `useAuthPremiumUserAttributesEditUpdateAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPremiumUserAttributesEditUpdateAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPremiumUserAttributesEditUpdateAttributesMutation, { data, loading, error }] = useAuthPremiumUserAttributesEditUpdateAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthPremiumUserAttributesEditUpdateAttributesMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthPremiumUserAttributesEditUpdateAttributesDocument, options);
}
export var AuthResignNewResignDocument = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    mutation AuthResignNewResign {\n  resign(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthResignNewResign {\n  resign(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthResignNewResignMutation__
 *
 * To run a mutation, you first call `useAuthResignNewResignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthResignNewResignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authResignNewResignMutation, { data, loading, error }] = useAuthResignNewResignMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthResignNewResignMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthResignNewResignDocument, options);
}
export var AuthCancelNewCancelDocument = gql(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    mutation AuthCancelNewCancel($input: CancelInput!) {\n  cancel(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthCancelNewCancel($input: CancelInput!) {\n  cancel(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthCancelNewCancelMutation__
 *
 * To run a mutation, you first call `useAuthCancelNewCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthCancelNewCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authCancelNewCancelMutation, { data, loading, error }] = useAuthCancelNewCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthCancelNewCancelMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthCancelNewCancelDocument, options);
}
export var AuthUsersEditUpdateUserDocument = gql(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    mutation AuthUsersEditUpdateUser($input: UpdateUserInput!) {\n  updateUser(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthUsersEditUpdateUser($input: UpdateUserInput!) {\n  updateUser(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthUsersEditUpdateUserMutation__
 *
 * To run a mutation, you first call `useAuthUsersEditUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthUsersEditUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authUsersEditUpdateUserMutation, { data, loading, error }] = useAuthUsersEditUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthUsersEditUpdateUserMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthUsersEditUpdateUserDocument, options);
}
export var AuthUsersShowResendEmailUpdateConfirmationEmailDocument = gql(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    mutation AuthUsersShowResendEmailUpdateConfirmationEmail {\n  resendEmailUpdateConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthUsersShowResendEmailUpdateConfirmationEmail {\n  resendEmailUpdateConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthUsersShowResendEmailUpdateConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useAuthUsersShowResendEmailUpdateConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthUsersShowResendEmailUpdateConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authUsersShowResendEmailUpdateConfirmationEmailMutation, { data, loading, error }] = useAuthUsersShowResendEmailUpdateConfirmationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthUsersShowResendEmailUpdateConfirmationEmailMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthUsersShowResendEmailUpdateConfirmationEmailDocument, options);
}
export var AuthUsersShowCreateCustomerPortalSessionDocument = gql(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n    mutation AuthUsersShowCreateCustomerPortalSession {\n  session: createCustomerPortalSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthUsersShowCreateCustomerPortalSession {\n  session: createCustomerPortalSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useAuthUsersShowCreateCustomerPortalSessionMutation__
 *
 * To run a mutation, you first call `useAuthUsersShowCreateCustomerPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthUsersShowCreateCustomerPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authUsersShowCreateCustomerPortalSessionMutation, { data, loading, error }] = useAuthUsersShowCreateCustomerPortalSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthUsersShowCreateCustomerPortalSessionMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AuthUsersShowCreateCustomerPortalSessionDocument, options);
}
export var ContactsNewSendContactDocument = gql(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    mutation ContactsNewSendContact($input: SendContactInput!) {\n  sendContact(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation ContactsNewSendContact($input: SendContactInput!) {\n  sendContact(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useContactsNewSendContactMutation__
 *
 * To run a mutation, you first call `useContactsNewSendContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactsNewSendContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactsNewSendContactMutation, { data, loading, error }] = useContactsNewSendContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactsNewSendContactMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ContactsNewSendContactDocument, options);
}
export var UnAuthConfirmationNewResendSignUpConfirmationEmailDocument = gql(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n    mutation UnAuthConfirmationNewResendSignUpConfirmationEmail($email: String!) {\n  resendSignUpConfirmationEmail(input: {email: $email}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation UnAuthConfirmationNewResendSignUpConfirmationEmail($email: String!) {\n  resendSignUpConfirmationEmail(input: {email: $email}) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useUnAuthConfirmationNewResendSignUpConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useUnAuthConfirmationNewResendSignUpConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnAuthConfirmationNewResendSignUpConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unAuthConfirmationNewResendSignUpConfirmationEmailMutation, { data, loading, error }] = useUnAuthConfirmationNewResendSignUpConfirmationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUnAuthConfirmationNewResendSignUpConfirmationEmailMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UnAuthConfirmationNewResendSignUpConfirmationEmailDocument, options);
}
export var ExecutePasswordResetDocument = gql(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n    mutation executePasswordReset($input: ExecutePasswordResetInput!) {\n  executePasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation executePasswordReset($input: ExecutePasswordResetInput!) {\n  executePasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useExecutePasswordResetMutation__
 *
 * To run a mutation, you first call `useExecutePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecutePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executePasswordResetMutation, { data, loading, error }] = useExecutePasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecutePasswordResetMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExecutePasswordResetDocument, options);
}
export var StartPasswordResetDocument = gql(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n    mutation startPasswordReset($input: StartPasswordResetInput!) {\n  startPasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation startPasswordReset($input: StartPasswordResetInput!) {\n  startPasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useStartPasswordResetMutation__
 *
 * To run a mutation, you first call `useStartPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPasswordResetMutation, { data, loading, error }] = useStartPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPasswordResetMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StartPasswordResetDocument, options);
}
export var SignInDocument = gql(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n    mutation signIn($input: SignInInput!) {\n  signIn(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation signIn($input: SignInInput!) {\n  signIn(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SignInDocument, options);
}
export var ExampleQueryDocument = gql(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n    mutation ExampleQuery($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation ExampleQuery($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
/**
 * __useExampleQueryMutation__
 *
 * To run a mutation, you first call `useExampleQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExampleQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exampleQueryMutation, { data, loading, error }] = useExampleQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExampleQueryMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExampleQueryDocument, options);
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28;
