import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import PageLayout from '@main/ui/components/PageLayout';
import classnames from 'classnames';
import useHook from './hook';
var Component = function (props) {
    var title = '活動履歴';
    var _a = useHook(props), days = _a.days, beginningOfMonth = _a.beginningOfMonth, selectedDayData = _a.selectedDayData, goPreviousMonth = _a.goPreviousMonth, goNextMonth = _a.goNextMonth, goCurrentMonth = _a.goCurrentMonth, handleDateClick = _a.handleDateClick;
    var buttonClasses = 'active:bg-gray-100 border border-gray-300 border-solid h-[32px] min-w-[32px] flex items-center justify-center text-base text-gray-600 first:rounded-l-sm last:rounded-r-sm first:ml-0 ml-[-1px]';
    return (<PageLayout caption={title} breadcrumbs={[{ caption: 'トップ', link: '/' }, { caption: title }]}>
      <p className="text-base text-gray-800">
        シェークハンズでの活動をカレンダー形式で確認できます。
        <br />
        練習の記録もつけることができます。
      </p>

      <div className="lg:flex lg:h-full lg:flex-col">
        <header className="flex flex-col items-center justify-between space-y-3 border-b border-gray-200 py-4 md:flex-row lg:flex-none">
          <div className="text-lg font-bold text-gray-800">
            {beginningOfMonth.format('YYYY年M月')}
          </div>

          <div className="flex items-center space-x-6">
            <div className="flex">
              <button type="button" onClick={goPreviousMonth} className={buttonClasses}>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
              </button>

              <button type="button" onClick={goCurrentMonth} className={classnames(buttonClasses, 'px-4')}>
                今日
              </button>

              <button type="button" onClick={goNextMonth} className={buttonClasses}>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
              </button>
            </div>

            <div className="h-6 w-px bg-gray-300"/>

            <a href="/practices/new" className="rounded bg-orange-600 px-3 py-2 text-sm font-semibold text-white active:bg-orange-700">
              練習記録を追加
            </a>
          </div>
        </header>

        <div className="ring-1 ring-gray-300 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-solid border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div className="bg-white py-2">月</div>
            <div className="bg-white py-2">火</div>
            <div className="bg-white py-2">水</div>
            <div className="bg-white py-2">木</div>
            <div className="bg-white py-2">金</div>
            <div className="bg-white py-2">土</div>
            <div className="bg-white py-2">日</div>
          </div>

          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="grid w-full grid-cols-7 gap-px">
              {days.map(function (day) { return (<button type="button" onClick={function () { return handleDateClick(day.date); }} key={day.date.format('YYYY-MM-DD')} className={classnames('relative flex flex-col p-2 text-left active:bg-gray-100 md:min-h-[100px] md:cursor-default md:active:bg-white', day.isCurrentMonth
                ? 'bg-white'
                : 'bg-gray-50 text-gray-500')}>
                  <time className={classnames('flex h-6 w-6 items-center justify-center rounded-full ', day.isToday && 'bg-orange-600 font-semibold text-white')}>
                    {day.date.date()}
                  </time>

                  {day.events.length > 0 && (<>
                      {/* PC版 */}
                      <ol className="mt-2 hidden w-full gap-y-1 md:grid">
                        {day.events.slice(0, 5).map(function (event) { return (<li key={event.id} className="w-full overflow-hidden">
                            <a href={event.href} className={classnames('block w-full truncate rounded-sm py-[3px] px-2 text-left text-sm font-medium leading-none text-black', event.href
                        ? 'cursor-pointer'
                        : 'cursor-default', {
                        'bg-lime-300': event.type === 'movie',
                        'bg-amber-300': event.type === 'practice',
                        'bg-blue-300': event.type === 'complete',
                        'bg-fuchsia-300': event.type === 'comment',
                    })}>
                              {event.title}
                            </a>
                          </li>); })}
                      </ol>

                      {/* SP版 */}
                      <div className="mt-1 ml-2 flex gap-1 empty:hidden md:hidden">
                        {day.hasPractice && (<div className="h-[8px] w-[8px] rounded-full bg-amber-300"/>)}

                        {day.hasMovie && (<div className="h-[8px] w-[8px] rounded-full bg-lime-300"/>)}

                        {day.hasComment && (<div className="h-[8px] w-[8px] rounded-full bg-fuchsia-300"/>)}

                        {day.hasComplete && (<div className="h-[8px] w-[8px] rounded-full bg-blue-300"/>)}
                      </div>
                    </>)}
                </button>); })}
            </div>
          </div>
        </div>

        {selectedDayData && selectedDayData.events.length > 0 && (<div className="mt-5 lg:hidden">
            <ol className="divide-y divide-solid divide-gray-300 overflow-hidden rounded bg-white text-sm ring-1 ring-gray-300">
              {selectedDayData.events.map(function (event) { return (<li key={event.id} className="group flex p-4 pr-6">
                  <a href={event.href} className="flex-auto">
                    <p className="font-semibold text-gray-900">{event.title}</p>
                  </a>
                </li>); })}
            </ol>
          </div>)}
      </div>
    </PageLayout>);
};
export default Component;
