import SectionHeader from '@main/ui/atoms/SectionHeader';
import CloudflareStreamPlayer from '@main/ui/components/CloudflareStreamPlayer';
import VimeoPlayer from '@main/ui/components/VimeoPlayer';
var Component = function (_a) {
    var title = _a.title, content = _a.content, vimeoId = _a.vimeoId, cloudflareStreamId = _a.cloudflareStreamId;
    return (<div className="w-full">
      <SectionHeader caption="シェークハンズからのお知らせ"/>
      <div className="flex flex-col gap-y-3 sm:flex-row sm:gap-x-5">
        <div className="sm:w-5/12">
          {cloudflareStreamId ? (<CloudflareStreamPlayer src={cloudflareStreamId}/>) : (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <VimeoPlayer videoId={vimeoId} aspectRatio={58.82}/>)}
        </div>
        <div className="flex-1 text-gray-600">
          <div className="mb-2.5 text-lg font-bold">{title}</div>
          <div dangerouslySetInnerHTML={{
            __html: content,
        }}/>
        </div>
      </div>
    </div>);
};
export default Component;
