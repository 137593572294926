import { z } from 'zod';
import { dayjs } from '@/utils/dayjs';
export var propsSchema = z.object({
    today: z.string().transform(function (value) { return dayjs(value).tz(); }),
    beginningOfMonth: z.string().transform(function (value) { return dayjs(value).tz(); }),
    startDate: z.string().transform(function (value) { return dayjs(value).tz(); }),
    endDate: z.string().transform(function (value) { return dayjs(value).tz(); }),
    dayCount: z.number(),
    practices: z.array(z.object({
        id: z.string(),
        date: z.string().transform(function (value) { return dayjs(value).tz(); }),
        placeName: z.string().nullable(),
    })),
    articleUserCompletes: z.array(z.object({
        id: z.number(),
        articleId: z.number(),
        createdAt: z.string().transform(function (value) { return dayjs(value).tz(); }),
    })),
    articleUserComments: z.array(z.object({
        id: z.number(),
        articleId: z.number(),
        updatedAt: z.string().transform(function (value) { return dayjs(value).tz(); }),
    })),
    articleUserHistories: z.array(z.object({
        id: z.number(),
        articleId: z.number(),
        viewedAt: z.string().transform(function (value) { return dayjs(value).tz(); }),
    })),
});
