import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideMenu from '@main/ui/components/SideMenu';
import classnames from 'classnames';
import { clickVisitLessonPageButton, clickVisitArticlePageButton, clickVisitCategoryPageButton, } from '@/utils/analytics/log-event';
import AttributeUpdatedModal from './components/AttributeUpdatedModal';
import CancelCompletedModal from './components/CancelCompletedModal';
import ConfirmationFailedModal from './components/ConfirmationFailedModal';
import ConfirmationInstructionResentModal from './components/ConfirmationInstructionResentModal';
import ConfirmedModal from './components/ConfirmedModal';
import CreditCardRegisteredModal from './components/CreditCardRegisteredModal';
import InProgressLessonCourse from './components/InProgressLessonCourse';
import InformationArea from './components/InformationArea';
import LiveSchedule from './components/LiveSchedule';
import PasswordResetModal from './components/PasswordResetModal';
import PostModal from './components/PostModal';
import ProblemBasedPosts from './components/ProblemBasedPosts';
import RecommendedLessonCourse from './components/RecommendedLessonCourse';
import ShandsFeed from './components/ShandsFeed';
import ThanksToUpgradeModal from './components/ThanksToUpgradeModal';
import TopicMovieList from './components/TopicMovieList';
import TopicPostList from './components/TopicPostList';
import useHook from './hook';
import hiraokaTheory2023Image from './images/hiraoka-theory-2023.jpg';
import hiraokaTheoryImage from './images/hiraoka-theory.jpg';
var Component = function (props) {
    var _a, _b, _c;
    var _d = useHook(props), newsMovie = _d.newsMovie, trialEndAt = _d.trialEndAt, recentPosts = _d.recentPosts, liveSchedules = _d.liveSchedules, sideMenuItems = _d.sideMenuItems, recentArticles = _d.recentArticles, advanceArticles = _d.advanceArticles, starterArticles = _d.starterArticles, shandsFeedProps = _d.shandsFeedProps, pendingPostModal = _d.pendingPostModal, beginnerArticles = _d.beginnerArticles, isLiveInProgress = _d.isLiveInProgress, problemBasedPosts = _d.problemBasedPosts, isArticleCompleted = _d.isArticleCompleted, recommendedArticles = _d.recommendedArticles, closeConfirmedModal = _d.closeConfirmedModal, isConfirmedModalOpen = _d.isConfirmedModalOpen, intermediateArticles = _d.intermediateArticles, annualPopularArticles = _d.annualPopularArticles, lastWeekPopularArticles = _d.lastWeekPopularArticles, closePasswordResetModal = _d.closePasswordResetModal, isPasswordResetModalOpen = _d.isPasswordResetModalOpen, closeCancelCompletedModal = _d.closeCancelCompletedModal, closeThanksToUpgradeModal = _d.closeThanksToUpgradeModal, isThanksToUpgradeModalOpen = _d.isThanksToUpgradeModalOpen, isCancelCompletedModalOpen = _d.isCancelCompletedModalOpen, closeAttributeUpdatedModal = _d.closeAttributeUpdatedModal, closePendingPostModalModal = _d.closePendingPostModalModal, isPendingPostModalModalOpen = _d.isPendingPostModalModalOpen, inProgressLessonCourseProps = _d.inProgressLessonCourseProps, isAttributeUpdatedModalOpen = _d.isAttributeUpdatedModalOpen, recommendedLessonCourseProps = _d.recommendedLessonCourseProps, closeConfirmationFailedModal = _d.closeConfirmationFailedModal, isConfirmationFailedModalOpen = _d.isConfirmationFailedModalOpen, closeCreditCardRegisteredModal = _d.closeCreditCardRegisteredModal, isCreditCardRegisteredModalOpen = _d.isCreditCardRegisteredModalOpen, closeConfirmationInstructionResentModal = _d.closeConfirmationInstructionResentModal, isConfirmationInstructionResentModalOpen = _d.isConfirmationInstructionResentModalOpen;
    var menuItems = [
        {
            caption: 'フォアハンド',
            link: '/categories/forehand',
            color: '#ff0000',
            clicked: function () {
                clickVisitCategoryPageButton('forehand', 'mobile_top');
            },
        },
        {
            caption: 'サーブ',
            link: '/categories/serve',
            color: '#90ee90',
            clicked: function () {
                clickVisitCategoryPageButton('serve', 'mobile_top');
            },
        },
        {
            caption: 'その他のテクニックで探す',
            link: '/categories/technique',
            color: '#6a5acd',
            clicked: function () {
                clickVisitCategoryPageButton('technique', 'mobile_top');
            },
        },
        {
            caption: 'コーチ・選手で探す',
            link: '/categories/coaches',
            color: '#87ceeb',
            clicked: function () {
                clickVisitCategoryPageButton('coaches', 'mobile_top');
            },
        },
        {
            caption: 'テーマで探す',
            link: '/categories/level',
            color: '#ff8c00',
            clicked: function () {
                clickVisitCategoryPageButton('level', 'mobile_top');
            },
        },
        {
            caption: 'レッスンコースで探す',
            link: '/lesson_course',
            color: '#a9a9a9',
            clicked: function () {
                clickVisitLessonPageButton('mobile_top');
            },
        },
    ];
    return (<>
      <div className="sm:mt-3">
        <div className="px-3 sm:mx-auto sm:flex sm:max-w-screen-xl sm:px-5">
          <div className="sm:mr-[30px] sm:w-[280px]">
            <div className="mb-5 grid grid-cols-2 sm:hidden">
              {menuItems.map(function (menuItem, index) { return (<a href={menuItem.link} onClick={menuItem.clicked} key={menuItem.caption} className={classnames('flex h-[40px] items-center overflow-hidden even:ml-[-1px] active:bg-gray-100', index >= 2 && 'mt-[-1px]')}>
                  <div className="h-full w-[5px]" style={{ backgroundColor: menuItem.color }}></div>
                  <div style={{ borderLeft: 0 }} className={classnames('flex h-full flex-1 items-center border border-solid border-gray-200 pl-2 text-sm font-bold', index === 1 && 'rounded-tr', index === 5 && 'rounded-br')}>
                    {menuItem.caption}
                  </div>
                </a>); })}
            </div>

            {trialEndAt && (<a href="/billing/trial" className="mb-5 hidden items-start rounded bg-yellow-100 py-1 px-2 leading-none text-yellow-700 ring-1 ring-yellow-300 active:bg-yellow-200 sm:flex">
                <FontAwesomeIcon fixedWidth size="1x" icon={faInfoCircle} className="mt-1 mr-1"/>

                <div className="flex-1 text-base">
                  ただいま無料体験中です。
                  <br />
                  <span className="underline">詳細はこちら</span>
                </div>
              </a>)}

            <div className="mb-5 grid gap-y-3">
              {[
            {
                href: '/hiraoka_theories',
                image: hiraokaTheoryImage,
            },
            {
                href: '/hiraoka_theories_2023',
                image: hiraokaTheory2023Image,
            },
        ].map(function (item) { return (<a key={item.href} href={item.href} className="block w-full">
                  <img alt="特集" loading="lazy" src={item.image} className="block w-full rounded"/>
                </a>); })}
            </div>

            <div className="hidden rounded-lg bg-gray-100 px-3 pt-4 pb-7 sm:block">
              <SideMenu sideMenuItems={sideMenuItems}/>
            </div>
          </div>
          <div className="flex flex-col gap-y-8 sm:w-[calc(100%_-_310px)]">
            {newsMovie && <InformationArea {...newsMovie}/>}

            {liveSchedules.length > 0 && (<LiveSchedule liveSchedules={liveSchedules} isLiveInProgress={isLiveInProgress}/>)}
            <InProgressLessonCourse props={inProgressLessonCourseProps}/>
            <RecommendedLessonCourse features={recommendedLessonCourseProps.features}/>
            <TopicMovieList caption="おすすめのレッスン動画" articles={recommendedArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () { return clickVisitArticlePageButton('recommended'); }}/>

            {problemBasedPosts.length > 0 && (<ProblemBasedPosts posts={problemBasedPosts}/>)}

            <TopicMovieList url="/articles/recent" caption="新着レッスン動画" articles={recentArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () { return clickVisitArticlePageButton('recent'); }}/>
            <TopicMovieList caption="今週の人気動画" articles={lastWeekPopularArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () {
            return clickVisitArticlePageButton('last_week_popular');
        }}/>
            <TopicMovieList caption="年間ランキング" articles={annualPopularArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () { return clickVisitArticlePageButton('annual_popular'); }}/>
            <TopicMovieList caption="入門者" url="/categories/starter" articles={starterArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () { return clickVisitArticlePageButton('starter'); }}/>
            <TopicMovieList caption="初級者" url="/categories/beginner" articles={beginnerArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () { return clickVisitArticlePageButton('beginner'); }}/>
            <TopicMovieList caption="中級者" url="/categories/intermediate" articles={intermediateArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () { return clickVisitArticlePageButton('intermediate'); }}/>
            <TopicMovieList caption="上級者" url="/categories/advance" articles={advanceArticles} isArticleCompleted={isArticleCompleted} itemLogEvent={function () { return clickVisitArticlePageButton('advanced'); }}/>
            <TopicPostList caption="お知らせ" url="/category/news" posts={recentPosts}/>
          </div>
        </div>
        <ShandsFeed {...shandsFeedProps}/>
      </div>

      {/* パスワードリセット完了後モーダル */}
      <PasswordResetModal close={closePasswordResetModal} isOpen={isPasswordResetModalOpen}/>

      {/* アンケート回答後モーダル */}
      <AttributeUpdatedModal close={closeAttributeUpdatedModal} isOpen={isAttributeUpdatedModalOpen}/>

      {/* クレジットカード登録後モーダル */}
      <CreditCardRegisteredModal close={closeCreditCardRegisteredModal} isOpen={isCreditCardRegisteredModalOpen}/>

      {/* メールアドレスの開通確認メール再送完了モーダル */}
      <ConfirmationInstructionResentModal close={closeConfirmationInstructionResentModal} isOpen={isConfirmationInstructionResentModalOpen}/>

      {/* メールアドレスの開通確認完了モーダル */}
      <ConfirmedModal close={closeConfirmedModal} isOpen={isConfirmedModalOpen}/>

      {/* 解約手続き完了モーダル */}
      <CancelCompletedModal close={closeCancelCompletedModal} isOpen={isCancelCompletedModalOpen}/>

      {/* 試用期間を途中終了してプレミアムサービス開始したユーザーへの感謝モーダル */}
      <ThanksToUpgradeModal close={closeThanksToUpgradeModal} isOpen={isThanksToUpgradeModalOpen}/>

      {/* メールアドレスの開通確認失敗モーダル */}
      <ConfirmationFailedModal close={closeConfirmationFailedModal} isOpen={isConfirmationFailedModalOpen}/>

      {/* メールアドレスの開通確認失敗モーダル */}
      <PostModal id={(_a = pendingPostModal === null || pendingPostModal === void 0 ? void 0 : pendingPostModal.id) !== null && _a !== void 0 ? _a : null} title={(_b = pendingPostModal === null || pendingPostModal === void 0 ? void 0 : pendingPostModal.title) !== null && _b !== void 0 ? _b : null} imageUrl={(_c = pendingPostModal === null || pendingPostModal === void 0 ? void 0 : pendingPostModal.imageUrl) !== null && _c !== void 0 ? _c : null} close={closePendingPostModalModal} isOpen={isPendingPostModalModalOpen}/>
    </>);
};
export default Component;
