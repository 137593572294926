import Button from '@main/ui/components/Button';
import PageLayout from '@main/ui/components/PageLayout';
import { Controller } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import useHook from './hook';
var Component = function (props) {
    var title = '練習記録の登録（ベータ版）';
    var _a = useHook(props), submit = _a.submit, control = _a.control, isSubmitting = _a.isSubmitting;
    return (<PageLayout singleColumn caption={title} breadcrumbs={[
            { caption: 'トップ', link: '/' },
            { caption: '活動履歴', link: '/activities' },
            { caption: '練習記録の登録' },
        ]}>
      <form onSubmit={submit}>
        <p className="mb-4 text-base">
          本ページでは練習を記録することができます。
        </p>

        <div className="mb-10 grid gap-y-5 rounded border border-solid border-gray-300 p-5">
          <Controller name="date" control={control} render={function (_a) {
            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
            return (<div>
                <p className="mb-0.5 text-base text-gray-700">練習日</p>

                <input ref={ref} type="date" value={value} onChange={onChange} className="block h-auto w-full overflow-y-hidden rounded-sm !border-none !bg-gray-100 py-1 px-2 text-base !shadow-none outline-none"/>

                {error && (<div className="mt-1 text-sm text-red-500">
                    {error.message}
                  </div>)}
              </div>);
        }}/>

          <Controller name="place" control={control} render={function (_a) {
            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
            return (<div>
                <p className="mb-0.5 text-base text-gray-700">練習場所</p>

                <input ref={ref} type="text" value={value} onChange={onChange} placeholder="（例）シェークハンズクラブ" className="block h-auto w-full overflow-y-hidden rounded-sm !border-none !bg-gray-100 py-1 !px-2 !text-base !shadow-none outline-none"/>

                {error && (<div className="mt-1 text-sm text-red-500">
                    {error.message}
                  </div>)}
              </div>);
        }}/>

          <Controller name="comment" control={control} render={function (_a) {
            var _b = _a.field, ref = _b.ref, value = _b.value, onChange = _b.onChange, error = _a.fieldState.error;
            return (<div>
                <p className="mb-0.5 text-base text-gray-700">コメント</p>

                <TextareaAutosize ref={ref} value={value} rows={5} minRows={5} onChange={onChange} placeholder="練習の内容・良かったこと・反省点など、ご自由に入力ください。" className="block h-auto w-full resize-none overflow-y-hidden rounded-sm !border-none !bg-gray-100 py-1 px-2 text-base !shadow-none outline-none"/>

                {error && (<div className="mt-1 text-sm text-red-500">
                    {error.message}
                  </div>)}
              </div>);
        }}/>

          <div className="flex justify-center">
            <Button type="submit" caption="保存する" state={isSubmitting ? 'submitting' : 'enabled'}/>
          </div>
        </div>

        <div className="flex justify-center">
          <a href="/activities" className="py-1 px-2 text-base text-gray-500 underline active:text-gray-600">
            活動履歴に戻る
          </a>
        </div>
      </form>
    </PageLayout>);
};
export default Component;
